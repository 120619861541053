<template lang="pug">
    .main-wrapper.funcionario-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "funcionario" }'> Usuários</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } usuário`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.first_name.$error }")
                                label.form-label Nome:
                                InputText(type='text' v-model='$v.model.first_name.$model')
                                .feedback--errors(v-if='submitted && $v.model.first_name.$error')
                                    .form-message--error(v-if="!$v.model.first_name.minLength") <b>Nome</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.first_name.required") <b>Nome</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.last_name.$error }")
                                label.form-label Sobrenome:
                                InputText(type='text' v-model='$v.model.last_name.$model')
                                .feedback--errors(v-if='submitted && $v.model.last_name.$error')
                                    .form-message--error(v-if="!$v.model.last_name.minLength") <b>Sobrenome</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.last_name.required") <b>Sobrenome</b> é obrigatório.

                            // .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ds_setor.$error }")
                            //     label.form-label Setor:
                            //     InputText(type='text' v-model='$v.model.ds_setor.$model')
                            //     .feedback--errors(v-if='submitted && $v.model.ds_setor.$error')
                            //         .form-message--error(v-if="!$v.model.ds_setor.minLength") <b>Setor</b> deve ter pelo menos 2 caracteres.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': (submitted && $v.model.username.$error) || usernameAlreadyExists }")
                                label.form-label Usuário:
                                InputText(type='text' v-model='$v.model.username.$model' @blur='checkUsername' @input='usernameAlreadyExists = false' :disabled='model.id')
                                .feedback--errors(v-if='(submitted && $v.model.username.$error) || usernameAlreadyExists')
                                    .form-message--error(v-if="!$v.model.username.minLength") <b>Usuário</b> deve ter pelo menos 6 caracteres.
                                    .form-message--error(v-if="!$v.model.username.required") <b>Usuário</b> é obrigatório.
                                    .form-message--error(v-if="usernameAlreadyExists") <b>Nome de usuário</b> já cadastrado.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.email.$error }")
                                label.form-label E-mail:
                                InputText(type='text' v-model='$v.model.email.$model')
                                .feedback--errors(v-if='submitted && $v.model.email.$error')
                                    .form-message--error(v-if="!$v.model.email.email") <b>E-mail</b> é inválido.
                                    .form-message--error(v-if="!$v.model.email.required") <b>E-mail</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ds_senha.$error }")
                                label.form-label {{ model.id ? 'Nova senha' : 'Senha' }}:
                                Password(v-model='$v.model.ds_senha.$model'
                                    :placeholder="model.id ? '(Deixe em branco se não quiser alterar)' : ''"
                                    promptLabel='Crie uma senha' weakLabel='Fraca' mediumLabel='Média' strongLabel='Forte'
                                )
                                .feedback--errors(v-if='submitted && $v.model.ds_senha.$error')
                                    .form-message--error(v-if="!$v.model.ds_senha.minLength") <b>Senha</b> deve ter pelo menos 6 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_senha.required") <b>Senha</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ds_senha_rep.$error }")
                                label.form-label Confirmação de senha:
                                InputText(type='password' v-model='$v.model.ds_senha_rep.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_senha_rep.$error')
                                    .form-message--error(v-if="!$v.model.ds_senha_rep.sameAs") As senhas digitadas são diferentes.
                                    .form-message--error(v-if="!$v.model.ds_senha_rep.required") <b>Confirmação de senha</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_empresa.$error }")
                                label.form-label Empresa:
                                Dropdown(:options='options.empresas' v-model='$v.model.cd_empresa.$model' optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_empresa.$error')
                                    .form-message--error(v-if="!$v.model.cd_empresa.required") <b>Estabelecimento</b> é obrigatório.

                            // .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_grupos.$error }")
                            //     label.form-label Grupos:
                            //     MultiSelect(:options='options.grupos' v-model='$v.model.cd_grupos.$model' optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione')
                            //     .feedback--errors(v-if='submitted && $v.model.cd_grupos.$error')
                            //         .form-message--error(v-if="!$v.model.cd_grupos.required") <b>Grupo</b> é obrigatório.

                            .p-col-6.p-md-3.ta-center
                                label.form-label Habilitado:
                                InputSwitch(v-model='model.is_active')

                            
                            p-col-6.p-md-12.ta-center.mt-2
                                    Button(label='Salvar' type="submit" style='width:150px !important')

                
</template>

<style lang="scss">
    .funcionario-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import InputSwitch from 'primevue/inputswitch'
    import Dropdown from 'primevue/dropdown'

    import { Funcionario, Usuario, Empresa } from './../../middleware'
    import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, InputSwitch, Password, Dropdown
        },
        created () {

                Empresa.findAll().then(response => {
                    if (response.status == 200) response.data.forEach(e => this.options.empresas.push({ value: e.id, text: e.nm_fantasia }))

                    let id = 0
                    
                    if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                    
                    if (id != 0) {
                        Funcionario.find(id).then(response => {
                            if (response.status == 200) {
                                response.data.grupos.forEach(grupo => this.model.cd_grupos.push(grupo.id))
                                response.data.estabelecimentos.forEach(estabelecimento => this.model.cd_empresa.push(estabelecimento.id))
                                let arrKeys = [ 'id', 'first_name', 'last_name', 'username', 'email', 'is_active','ds_setor']
                                arrKeys.forEach(key => this.model[key] = response.data[key])
                                this.currentUsername = response.data.username
                            }
                            this.waiting = false
                        })
                    } else this.waiting = false

                })
            
        },
        data () {
            return {
                model: {
                    first_name: '',
                    last_name: '',
                    ds_setor: '',
                    username: '',
                    email: '',
                    ds_senha: '',
                    ds_senha_rep: '',
                    is_active: true,
                    cd_grupos: [],
                    cd_empresa: [],
                },
                options: {
                    empresas: []
                },
                estabelecimentos: null,
                waiting: true,
                waitingForm: false,
                waitingGrupo_add: false,
                submitted: false,
                usernameAlreadyExists: false,
                currentUsername: ''
            }
        },
        validations () {
            let v = {
                model: {
                    first_name: { required, minLength: minLength(2), maxLength: maxLength(30) },
                    last_name: { required, minLength: minLength(2) },
                    username: { required, minLength: minLength(6) },
                    cd_empresa: {  },
                    email: { required, email },
                    ds_senha: {
                        required: this.model.id ? (this.model.ds_senha ? required : true) : required,
                        minLength: minLength(6)
                    },
                    ds_senha_rep: {
                        required: this.model.id ? (this.model.ds_senha ? required : true) : required,
                        sameAs: sameAs('ds_senha') }
                }
            }
            
            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0
                if (this.usernameAlreadyExists) return 0

                let dataSend = Object.assign({}, this.model)

                if (dataSend.id && ! dataSend.ds_senha) {
                    delete dataSend.ds_senha
                    delete dataSend.ds_senha_rep
                }

                this.waitingForm = true
                Empresa.saveUsuario(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        this.currentUsername = dataSend.username
                        if (! dataSend.id) this.$router.push({ name: 'revendedor' })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
            },
            checkUsername () {
                if (this.model.username.length >= 6) {
                    Usuario.find(this.model.username).then(response => {
                        if (response.status == 200 && this.model.username != this.currentUsername)
                            this.usernameAlreadyExists = true
                    })
                }
            }
        }
    }
</script>